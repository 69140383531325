<template>
  <div class="MERCH markcon">
    <div class="merch-card">
      <!-- 列表 -->
      <div
        :style="`max-height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table :data="reviewData" border style="width: 100%">
            <el-table-column prop="id" label="编号" align="center">
            </el-table-column>
            <el-table-column prop="name" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="day" label="分账时间（天）" align="center">
            </el-table-column>
            <el-table-column prop="sort" label="排序" align="center">
            </el-table-column>
            <el-table-column prop="count" label="使用供应商数" align="center">
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="170"
            >
              <template slot-scope="{ row }">
                <div class="userBtn" v-if="row.default != 1">
                  <div @click="toRead(row)" v-if="allJurisdiction.markcon145">
                    编辑
                  </div>
                  <!-- <div v-if="row.count == 0" @click="deltelFZ(row)">删除</div> -->
                  <el-popconfirm
                    v-if="row.count == 0 && allJurisdiction.markcon163"
                    @confirm="deltelFZ(row)"
                    title="确定删除当前分账规则？"
                  >
                    <div slot="reference">删除</div>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="addl" @click="addLie" v-if="allJurisdiction.markcon146">
            + 创建分账规则
          </div>
        </div>
      </div>
      <!-- 新建编辑弹框 -->
      <div>
        <el-dialog title="分账" :visible.sync="isFz" width="600px">
          <div>
            <el-form ref="form" label-width="120px">
              <el-form-item label="名称" required>
                <el-input v-model="FzData.name"></el-input>
              </el-form-item>
              <el-form-item label="分账时间 (天)" required>
                <el-input
                  v-model="FzData.day"
                  type="text"
                  oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                ></el-input>
              </el-form-item>
              <el-form-item label="排序">
                <el-input v-model="FzData.sort"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isFz = false">取 消</el-button>
            <el-button type="primary" @click="addFz">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      reviewApi: {},
      reviewData: [],
      isFz: false,
      FzData: {},
    };
  },
  computed: {
    ...commonIndex.mapState(["AreaList", "mainHright", "allJurisdiction"]),
  },
  created() {
    this.GETreviewList();
  },
  methods: {
    ...mapActions(["getreviewList"]),
    ...merchantCenterList.mapActions([
      "getbusiness_ledgerlist",
      "getbusiness_ledgeredit",
      "businessEdit",
      "postbusiness_ledgeradd",
      "postbusiness_ledgeredit",
      "postbusiness_ledgerdelete",
    ]),
    // 新建一列
    addLie() {
      this.FzData = {};
      this.isFz = true;
    },
    // 新建分账
    async addFz() {
      if (!this.FzData.name) {
        this.$message({
          message: "名称不能为空",
          type: "error",
        });
        return;
      }
      if (!this.FzData.day) {
        this.$message({
          message: "分账时间不能为空",
          type: "error",
        });
        return;
      }
      let data = {};
      if (!this.FzData.id) {
        data = await this.postbusiness_ledgeradd(this.FzData);
      } else {
        data = await this.postbusiness_ledgeredit(this.FzData);
      }
      if (data.code == 200) {
        this.isFz = false;
        this.GETreviewList();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 获取供应商审核列表
    async GETreviewList() {
      let data = {};
      data = await this.getbusiness_ledgerlist(this.reviewApi);
      if (data.code == 200) {
        this.reviewData = data.data;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    handleCurrentChange(e) {
      this.reviewApi.page = e;
      this.GETreviewList();
    },
    async toSearch() {
      let data = await this.getbusiness_ledgeredit();
      if (data.code == 200) {
      }
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    // 编辑数据
    async toRead(row) {
      let data = await this.getbusiness_ledgeredit({ id: row.id });
      if (data.code == 200) {
        this.FzData = data.data;
        this.isFz = true;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 删除
    async deltelFZ(row) {
      let data = await this.postbusiness_ledgerdelete({ id: row.id });
      if (data.code == 200) {
        this.GETreviewList();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 77%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
}
.addl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebeef5;
  border-top: none;
  cursor: pointer;
  color: #909399;
}
</style>
